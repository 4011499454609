/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Cuando más estudio el tema de la libertad en internet más importante me parece la privacidad."), "\n", React.createElement(_components.p, null, "Y no solo me pasa a mí, esto es algo común, tambien lo pensaron los creadores de GPG y Richard Stallman, por poner dos importantes ejemplos."), "\n", React.createElement(_components.p, null, "La pregunta es ¿porque estan tan relacionadas?"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
